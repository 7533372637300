#scene {
  position: absolute;
  top: 64px;
  width: 100% !important;
  max-width: 2500px;
  height: calc(100% - 64px) !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
}

#scene canvas {
  width: calc(100% - 4rem) !important;
  height: calc(100% - 2rem) !important;
  border-radius: 3rem;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.4);
  object-fit: cover;
}
.calibration-text {
  font-family: 'Poppins', sans-serif !important;
  font-size: 1.2rem;
  text-align: center;
}
.calibration-btn {
  font-family: 'Poppins', sans-serif;
  padding: 0.5rem 3rem;
  font-size: 1.2rem;
  background-color: #ff592c;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 0.5rem;
  box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.3);
}
.loader-container {
  overflow: hidden;
}

.MuiContainer-root {
  padding: 0 !important;
}
.avtar {
  height: 50px;
  width: 50px;
  border-radius: 30px;
  object-fit: cover;
}

.gamer {
  /* position: absolute; */
  z-index: 10000000000;
  bottom: 0.5rem;
  background-color: #fff;
  width: calc(100% - 4rem - 4rem);
  /* left: 50%;
  transform: translateX(-50%); */
  border-radius: 2rem;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}

.gamer-left {
  display: flex;
  align-items: center;
  column-gap: 2rem;
}

.gamer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamer-item img {
  width: 60px;
  border-radius: 30px;
  object-fit: cover;
}

.timer-container {
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.6);
  border-radius: 0.5rem;
  overflow: hidden;
}

.timer {
  background: linear-gradient(
    180deg,
    #444444 0%,
    rgba(102, 102, 102, 0.88) 100%
  );
  color: #fff;
  font-size: 1rem;
  text-align: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.arena-points-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 1rem;
  padding: 1rem;
}

.arena-points-container span,
.arena-points-container h2 {
  line-height: 1;
}

.arena-points-container img {
  width: 30px;
}

.loader-inner {
  width: 100%;
  height: calc(100vh - 64px);
  /* height: 60vh;
    max-height: 60vh; */
  overflow: hidden;
  background-image: url('../../assets/loader-background.png');
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.lines {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
}

.loader-inner .line {
  width: 20px;
  height: 200px;
  background: #ff2ac3;
  border-radius: 50vw;
  animation: animate 800ms linear infinite alternate;
}

.loader-inner h1 {
  margin-top: 8rem;
  color: #fff;
  font-weight: 500;
  font-size: 3rem;
}

.loader-inner .line2 {
  animation-delay: 200ms;
}

.loader-inner .line3 {
  animation-delay: 400ms;
}

.loader-inner .line4 {
  animation-delay: 600ms;
}

.loader-inner .line5 {
  animation-delay: 800ms;
}

.loader-inner .line6 {
  animation-delay: 1000ms;
}

@keyframes animate {
  from {
    transform: translateY(30%);
  }

  to {
    transform: translateY(-30%);
  }
}

@media (max-width: 2000px) {
  .lines {
    column-gap: 1.5rem;
  }

  .loader-inner .line {
    width: 15px;
    height: 150px;
  }

  .loader-inner h1 {
    margin-top: 6rem;
    font-size: 2.5rem;
  }
}

@media (max-width: 1500px) {
  .lines {
    column-gap: 1rem;
  }

  .loader-inner .line {
    width: 10px;
    height: 100px;
  }

  .loader-inner h1 {
    margin-top: 5rem;
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .loader-container,
  .loader-inner {
    height: calc(100vh - 56px);
  }

  #scene {
    top: 56px;
    height: calc(100vh - 56px) !important;
  }

  .exit-btn {
    height: 45px !important;
  }

  .arena-points-container span span {
    display: none;
  }
}

@media (max-width: 1550px) {
  .gamer > .participant:nth-child(6) {
    display: none;
  }

  .arena-points-container span {
    font-size: 0.8rem;
  }

  .arena-points-container h2 {
    font-size: 1rem;
  }

  .gamer {
    bottom: 0.25rem;
    background-color: #fff;
    width: calc(100% - 4rem - 2rem);
    padding-top: 0.3rem !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width: 1340px) {
  .gamer > .participant:nth-child(5) {
    display: none;
  }
}

@media (max-width: 1170px) {
  .gamer > .participant:nth-child(4) {
    display: none;
  }
}

@media (max-width: 1030px) {
  .gamer > .participant:nth-child(3) {
    display: none;
  }
}

@media (max-width: 1000px) {
  #scene {
    top: calc(64px + 2rem);
    height: calc(100% - 64px - 2rem) !important;
  }
}

@media (max-width: 920px) {
  .gamer {
    padding-bottom: 0.4rem !important;
  }
  .gamer-item img {
    width: 40px !important;
  }

  .timer {
    font-size: 0.9rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .arena-points-container {
    column-gap: 0.5rem;
    padding: 0.3rem;
  }

  .arena-points-container img {
    width: 25px;
  }
  .gamer {
    padding-bottom: 0.4rem !important;
  }
}

@media (max-width: 650px) {
  .gamer-item img {
    width: 35px !important;
  }

  .timer {
    font-size: 0.8rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .arena-points-container {
    column-gap: 0.5rem;
    padding: 0.3rem;
  }

  .arena-points-container img {
    width: 25px;
  }

  #scene canvas {
    border-radius: 2rem;
    width: calc(100% - 2rem) !important;
  }

  .gamer {
    bottom: 3rem;
    width: calc(100% - 4rem);
    border-radius: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .gamer-item span {
    font-size: 0.8rem;
  }
}

@media (max-width: 500px) {
  .gamer {
    padding-bottom: 0.4rem !important;
  }
  .gamer-left {
    column-gap: 1rem;
  }

  .gamer-item img {
    width: 30px !important;
  }

  .timer {
    font-size: 0.7rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  .arena-points-container {
    column-gap: 0.5rem;
    padding: 0.1rem 0.5rem;
  }

  .arena-points-container img {
    width: 20px;
  }

  .arena-points-container span {
    font-size: 0.8rem;
    line-height: 1;
  }

  .arena-points-container h2 {
    line-height: 1;
    font-size: 1rem;
  }

  .gamer-item span {
    font-size: 0.7rem;
  }
}

@media (max-width: 450px) {
  .exit-btn {
    width: 70px;
    right: 1rem !important;
  }
}

@media (max-width: 395px) {
  #scene canvas {
    border-radius: 2rem;
    width: calc(100% - 1rem) !important;
  }

  .gamer {
    width: calc(100% - 2rem);
  }

  .gamer-left {
    column-gap: 0.5rem;
  }
}

@media (max-height: 1200px) {
  .gamer-item img {
    width: 40px;
    border-radius: 20px;
  }

  .timer {
    font-size: 0.8rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
  }

  .arena-points-container {
    column-gap: 0.5rem;
    padding: 0.3rem;
  }

  .arena-points-container img {
    width: 25px;
  }
}

.yesno {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5%;
}

.detect-container {
  width: 30%;
}
.frame-container {
  width: 60%;
  margin-left: 8%;
}
.detect-container img {
  object-fit: contain;
  width: 80%;
  margin-top: -10%;
}

.frame-container iframe {
  height: 100%;
  width: 100%;
  box-shadow: none;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}
.all-cont {
  display: flex;
}
.iframe-loading {
  background: url('../../assets/loading.gif') center center no-repeat;
}
@media (max-width: 680px) {
  .detect-container {
    width: 100%;
  }
  .detect-container h1 {
    font-size: 1.2rem;
    text-align: center;
  }
  .frame-container {
    width: 100%;
    margin: 0%;
  }
  .frame-container #framer {
    margin: 0% !important;
    width: 100vw !important;
    height: 80vh !important;
  }
  .all-cont {
    flex-direction: column;
  }
}
