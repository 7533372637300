.class-home-content {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  margin-left: 60px;
  padding: 3rem;
  color: #23262f;
}

.class-home-inner {
  background-color: #fff;
  width: clamp(100px, 80vw, 1800px);
  margin: 0 auto;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.2),
    -1rem 0rem 1rem rgba(0, 0, 0, 0.2);
  border-radius: 3rem;
  padding: 2rem;
}

.class-home-content .top {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4rem;
}
.class-home-content .title {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}
.class-home-content .title .class-home-violet-rect {
  width: 20px;
  height: 40px;
  background-color: #cabdff;
  border-radius: 0.3rem;
}
.class-home-content .searchcont {
  background-color: #f4f4f4;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0.2rem 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
  overflow: hidden;
}
.class-home-content .searchcont img {
  width: 25px;
}
.class-home-content .searchcont input {
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 1rem;
  margin-left: 1rem;
}

.class-home-content .middle {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  margin-top: 5rem;
  border-bottom: 2px solid #f7f7f7;
  padding-bottom: 1rem;
}
.class-home-content .middle * {
  color: #8b9096;
  font-weight: 500;
}

.hoverable {
  cursor: pointer;
}
.hoverable:hover {
  background-color: #efefef;
}
.class-home-content .row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 4rem 0;
  border-bottom: 2px solid #f7f7f7;
}
.class-home-content .row div h3 {
  font-weight: 600;
}
.class-home-content .row div:nth-of-type(3) h3,
.class-home-content .row div:nth-of-type(4) h3,
.class-home-content .row div:nth-of-type(5) h3 {
  background-color: #efefef;
  padding: 0.3rem 0.5rem;
  display: inline-block;
  border-radius: 0.3rem;
}

@media (max-width: 1200px) {
  .class-home-content .row,
  .class-home-content .middle {
    grid-template-columns: repeat(4, 1fr);
  }
  .class-home-content .middle div:nth-child(2) {
    display: none;
  }
}

@media (max-width: 1000px) {
  .class-home-content .row,
  .class-home-content .middle {
    grid-template-columns: 1fr 1fr 5rem 1fr;
  }
}

@media (max-width: 860px) {
  .class-home-content .searchcont {
    padding: 0.5rem;
  }
  .class-home-content .searchcont img {
    width: 18px;
  }
  .class-home-content .searchcont input {
    width: 200px;
    font-size: 0.9rem;
  }

  .class-home-content .title .class-home-violet-rect {
    width: 15px;
    height: 30px;
    border-radius: 0.3rem;
  }
  .class-home-content .title h1 {
    font-size: 1.5rem;
  }

  .class-home-content .middle div,
  .class-home-content .bottom div {
    font-size: 0.8rem;
    text-align: center;
  }
}

@media (max-width: 650px) {
  .class-home-content .row,
  .class-home-content .middle {
    grid-template-columns: 1fr 1fr 3rem 7rem;
  }
}

@media (max-width: 599px) {
  .class-home-content {
    margin-left: 0;
    padding: 1rem;
  }
  .class-home-inner {
    width: clamp(100px, 90vw, 1800px);
  }
}

@media (max-width: 560px) {
  .class-home-content .top {
    column-gap: 2rem;
  }
  /* .class-home-content .middle div,
    .class-home-content .bottom div {
        text-align: left;
    } */
  .class-home-content .bottom div:last-of-type {
    text-align: center;
  }
  .class-home-inner {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

@media (max-width: 500px) {
  .class-home-content .searchcont img {
    width: 15px;
  }
  .class-home-content .searchcont input {
    width: 150px;
    font-size: 0.8rem;
    margin-left: 0.5rem;
  }

  .class-home-content .title .class-home-violet-rect {
    width: 10px;
    height: 25px;
    border-radius: 0.2rem;
  }
  .class-home-content .title h1 {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .class-home-inner {
    width: clamp(100px, 95vw, 1800px);
  }
  .class-home-content .middle div,
  .class-home-content .bottom div {
    font-size: 0.7rem;
  }
}

@media (max-width: 370px) {
  .class-home-content .top {
    column-gap: 1rem;
  }
}

@media (max-width: 340px) {
  .class-home-content .searchcont input {
    width: 125px;
  }
}
