.counts {
    width: fit-content;
    background-color: #FF592C;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-size: 1.5em;
    padding: 0.35rem;
    border-radius: 8px;
    position: relative;
}

.feedback {
    width: fit-content;
    background-color: #7448FF;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-size: 1.5rem;
    position: absolute;
    left: 2.5%;
    top: 10rem;
    padding: 0.5rem;
    border-radius: 8px;
    z-index: 10;
}

.sideFeedback {
    width: fit-content;
    background-color: #FF592C;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    font-size: 2.8rem;
    position: absolute;
    left: 15%;
    top: 10%;
    padding: 0.5rem;
    border-radius: 8px;
    text-align: center;
    z-index: 10;
}

.transformableCountsContainer {
    width: fit-content;
    position: absolute;
    display: flex;
    margin-top: 0.9rem;
    flex-direction: column;
    gap: 0.25em;
    right: 1.5%;
    top: 1.25rem;
    z-index: 10;
}

.counts_container {
    width: fit-content;
    position: absolute;
    display: flex;
    margin-top: 0.9rem;
    flex-direction: column;
    gap: 0.25em;
    right: 1.5%;
    top: 0.25rem;
}

@media screen and (max-width: 1271px) {
    .counts_container,
    .feedback {
        padding: 0.9rem;
        font-size: 1rem !important;
        top: 6rem;
        right: 5rem;
    }

    .feedback {
        left: 1rem;
    }
}

@media screen and (max-width: 1070px) {
    .counts_container,
    .feedback {
        padding: 0.75em 0.5em;
        font-size: 0.7em !important;
        top: calc(100vh - 90vh);
    }

    .correct {
        top: calc(100vh - 84vh);
    }
}

@media screen and (max-width: 770px) {
    .counts_container,
    .feedback {
        padding: 0.65em 0.4em;
        top: calc(100vh - 95vh);
        right: 2em;
        font-size: 0.55em !important;
    }

    .correct {
        top: calc(100vh - 88vh);
    }
}
