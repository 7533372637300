.summary-highlights-container {
    display: grid;
    width: 100%;
    margin-left: 20px;
    height: calc(100vh - 86px);
    /* z-index: -100; */
    background-color: rgb(252, 252, 249);
    align-items: center;
    justify-content: center;
    width: clamp(200px, 80vw, 2200px);
    /* min-width: 928px; */
}

.hightlights .today-highlights-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.hightlights .today-highlights-content .top {
    padding: 10px;
    display: grid;
    gap: 3rem;
    justify-content: center;
    align-items: flex-start;
}

.hightlights .today-highlights-content .bottom {
    padding: 10px;
    display: grid;
    grid-template-columns: 200px 200px 200px;
    gap: 3rem;
    justify-content: center;
    align-items: flex-start;
}

@media (max-width: 840px) {
    .hightlights .highlights-grid {
        grid-template-columns: 1fr;
        padding: 1rem 1rem 2rem;
    }

    .hightlights .highlights-grid {
        gap: 1rem;
    }

    .hightlights .my-rank {
        width: 300px;
        padding: 1rem 1rem 0.5rem;
        border-radius: 1rem;
    }

    .hightlights .bottom-box {
        border-radius: 1rem;
    }

    .hightlights .my-rank-content .right h1 {
        font-size: 1.2rem !important;
    }

    .hightlights .orange-box {
        width: 10px;
        height: 25px;
        border-radius: 0.2rem;
    }

    .hightlights .today-highlights-content .bottom {
        gap: 1rem;
        padding-bottom: 1rem;
        padding: 10px;
        display: grid;
        grid-template-columns: 120px 100px 110px;
        justify-content: center;
        align-items: flex-start;
    }

    .hightlights .my-rank-content .left > h1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 25px;
        padding-right: 20px;
        font-size: medium;
    }

    .summary-highlights-container {
        width: 90vw;
        display: flex;
        flex-direction: column;
        gap: 1em;
        height: fit-content;
    }

    .summary-highlights-container .top-bar {
        width: 100%;
    }
}

@media (max-width: 550px) {
    .hightlights .today-highlights-content {
        padding: 10px;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        height: fit-content;
    }

    .hightlights .today-highlights-content .bottom {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .hightlights .today-highlights-content .top {
        padding: 10px;
        display: flex;
        gap: 1rem;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }
}

.hightlights .my-rank-content .left > h1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 50px;
    padding-right: 40px;
}

.demo-next {
    width: fit-content !important;
    background-color: #ff592c !important;
    padding: 0.5rem 1rem !important;
    border-radius: 1rem !important;
}

.hightlights .top-bar,
.hightlights .top-bar .left {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.top-bar .right {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}
