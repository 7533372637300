.language-icons {
  flex: auto;
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #f4f4f4;
  border-radius: 20;
}

@media (max-width: 860px) {
  .language-icons {
    flex: auto;
    width: 18px !important;
    height: 18px !important;
    border: 1px solid #f4f4f4;
    border-radius: 20;
  }
}

@media (max-width: 460px) {
  .language-icons {
    flex: auto;
    width: 14px !important;
    height: 14px !important;
    border: 1px solid #f4f4f4;
    border-radius: 10;
  }
  .badge-notification {
    flex: auto;
    width: 14px !important;
    height: 14px !important;
  }
  .clinikal-logo {
    flex: auto;
    margin-right: 7rem;
  }
}

.language-placement .toggle-button-language {
  border: 0.5px solid #a95f5f;
  border-radius: 20;
  width: 2rem;
  height: 2rem;
  margin: auto;
}
