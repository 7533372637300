@import url(https://fonts.googleapis.com/css?family=Poppins:100,
  100italic,
  200,
  200italic,
  300,
  300italic,
  regular,
  italic,
  500,
  500italic,
  600,
  600italic,
  700,
  700italic,
  800,
  800italic,
  900,
  900italic);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif !important;
}

.teacher-home * {
  color: #1a1d1f;
}

.article-navigation .right .dashboard-top-select,
.top-performers-container .top span {
  color: #6f767e;
  font-weight: 500;
}

.points-container .single-point-container .right span {
  color: #848a91;
  font-weight: 600;
}

.bottom-right .today-session-list .left * {
  color: #777e90;
}

.menu-container * {
  color: #6f767e;
}

.container {
  display: grid;
  grid-template-columns: minmax(100px, 1500px) minmax(100px, 600px);
  grid-template-rows: minmax(100px, 500px) 10rem minmax(100px, 500px);
  gap: 5rem;
  place-content: center;
  margin: 0 auto 0 60px;
  padding: 0 40px;
  height: calc(100vh - 100px);
  max-height: 100%;
  grid-template-areas:
    'performers calendar'
    'performers session'
    'progressGraph session';
}

.article {
  background-color: #fff;
  border-radius: 3rem;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.3);
  padding: 3rem;
}

.top-left {
  grid-area: performers;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.teacher-top-left-container {
  position: relative;
}

.teacher-top-left-container .top-performers-container {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}

.top-right {
  grid-area: calendar;
}

.bottom-left {
  grid-area: progressGraph;
  padding: 2rem 4rem 6rem;
}

.bottom-right {
  grid-area: session;
}

/* ============================================== */
/* =========== TOP PERFORMERS - START =========== */
/* ============================================== */

.article-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.article-navigation .left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.orange-rect {
  width: 25px;
  height: 50px;
  background-color: #ff592c;
  border-radius: 0.5rem;
}

.article-navigation .left h1 {
  font-weight: 500;
}

.article-navigation .right .dashboard-top-select {
  font-size: 1.5rem;
  padding: 0rem 3rem;
  border-radius: 1rem;
  background-color: transparent;
  border: none;
  height: 50px;
}

.points-container {
  display: flex;
  background-color: #f4f4f4;
  margin-top: 3rem;
  padding: 1rem;
  border-radius: 2rem;
}

.points-container .single-point-container:first-child,
.points-container .single-point-container:last-child {
  background-color: #fff;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 2rem;
}

.points-container .single-point-container {
  flex-basis: 100%;
  padding: 2rem;

  display: flex;
  justify-content: center;
  gap: 2rem;
}

.points-container .single-point-container img {
  width: 50px;
}

.points-container .single-point-container .right span {
  font-size: 1.4rem;
}

.points-container .single-point-container .right h1 {
  font-size: 3rem;
}

.top-performers-container .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 3rem 0;
}

.top-performers-container .top * {
  font-size: 1.3rem;
}

.top-performers-container .top .find-me-box {
  outline: 2px solid #efefef;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  font-weight: 600;
}

.top-performers-container .bottom {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.top-performers-container .bottom .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.top-performers-container .bottom .item img {
  width: 100px;
  height: 100px;
  border-radius: 50px;
  object-fit: cover;
}

.top-performers-container .bottom .item span {
  text-align: center;
  margin-top: 0.5rem;
  font-weight: 500;
}

/* ============================================ */
/* =========== TOP PERFORMERS - END =========== */
/* ============================================ */

/* =============================================================================================================== */

/* ======================================== */
/* =========== CALENDAR - START =========== */
/* ======================================== */

/* .top-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 1rem;
}

.top-right .react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: center ;
  column-gap: 3rem;
}

.top-right .react-calendar__navigation button {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 500;
}

.top-right .react-calendar__navigation button:first-of-type,
.top-right .react-calendar__navigation button:last-of-type {
  outline: 2px solid rgba(0, 0, 0, .3);
  width: 40px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .3rem;
}

.top-right .react-calendar__navigation .react-calendar__navigation__prev2-button,
.top-right .react-calendar__navigation .react-calendar__navigation__next2-button {
  display: none;
}

.top-right .weekdays-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  width: 100%;
}

.top-right .days-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
  place-content: center;
}

.top-right .days-container .single-day {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
} */

/* ====================================== */
/* =========== CALENDAR - END =========== */
/* ====================================== */

/* =============================================================================================================== */

/* ======================================== */
/* =========== PROGRESS - START =========== */
/* ======================================== */

/* ====================================== */
/* =========== PROGRESS - END =========== */
/* ====================================== */

/* =============================================================================================================== */

/* ============================================= */
/* =========== TODAY SESSION - START =========== */
/* ============================================= */

.bottom-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 5rem;
}

.bottom-right .today-session-box {
  outline: 2px solid #e6e6e0;
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.3rem;
  font-weight: 500;
}

.bottom-right .today-session-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
  column-gap: 1rem;
  width: 100%;
}

.bottom-right .today-session-list div {
  font-size: 1.2rem;
  font-weight: 500;
}

.bottom-right .today-session-list .left {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
}

.bottom-right .today-session-list .right {
  text-align: right;
}

.bottom-right .text .divider {
  width: 100%;
  height: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem !important;
  margin-top: 0 !important;
}

.bottom-right .text p {
  font-size: 1.1rem;
}

.bottom-right .today-session-btn {
  font-family: 'Poppins', sans-serif;
  padding: 0.5rem 3rem;
  font-size: 1.2rem;
  background-color: #ff592c;
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 0.5rem;
  box-shadow: 0.2rem 0.2rem 0.3rem rgba(0, 0, 0, 0.3);
}

/* =========================================== */
/* =========== TODAY SESSION - END =========== */
/* =========================================== */

/* =============================================================================================================== */

/* ===================================== */
/* =========== MEDIA QUERIES =========== */
/* ===================================== */

/* ======================= */
/* ===== / GENERAL / ===== */
/* ======================= */

@media (max-width: 1820px) {
  .points-container {
    padding: 0.5rem;
  }

  .points-container .single-point-container {
    padding: 1rem;
    gap: 1rem;
  }

  .points-container .single-point-container img {
    width: 40px;
  }

  .points-container .single-point-container .right span {
    font-size: 1.2rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 2.4rem;
  }
}

@media (max-width: 1564px) {
  .container {
    grid-template-columns: minmax(100px, 1500px) minmax(100px, 500px);
    column-gap: 2rem;
  }

  .points-container {
    border-radius: 1rem;
  }

  .points-container .single-point-container:first-child,
  .points-container .single-point-container:last-child {
    border-radius: 1rem;
  }

  .points-container .single-point-container {
    padding: 0.5rem;
  }

  .points-container .single-point-container img {
    width: 30px;
  }

  .points-container .single-point-container .right span {
    font-size: 1rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 2rem;
  }
}

@media (max-width: 1285px) {
  .top-left {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .points-container .single-point-container .right span {
    font-size: 0.9rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 1.6rem;
  }

  .article-navigation .right .dashboard-top-select {
    font-size: 1rem;
    padding: 0 1rem;
    border-radius: 0.5rem;
  }
}

/* ======================= */
/* ===== / GENERAL / ===== */
/* ======================= */

/* ================== */
/* ===== / PC / ===== */
/* ================== */

@media (max-width: 2560px) and (max-height: 1325px) {
  .container {
    row-gap: 3rem;
  }
}

@media (max-width: 2560px) and (max-height: 1260px) {
  .article {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .bottom-left {
    padding-bottom: 6rem;
  }

  .points-container .single-point-container {
    padding: 0.5rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 2.5rem;
  }

  .points-container .single-point-container {
    padding: 1rem 0;
  }

  .top-performers-container .top {
    margin: 1.5rem 0;
  }
}

@media (max-width: 2050px) and (max-height: 1300px) {
  .container {
    grid-template-columns: minmax(100px, 1500px) minmax(100px, 500px);
  }

  .bottom-right {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}

@media (max-width: 2050px) and (max-height: 1080px) {
  .container {
    grid-template-columns: minmax(100px, 1500px) minmax(100px, 450px);
    grid-template-rows: minmax(100px, 500px) 5rem minmax(100px, 500px);
  }

  .orange-rect {
    width: 17px;
    height: 35px;
    border-radius: 0.4rem;
  }

  .article-navigation .left h1 {
    font-size: 1.5rem;
  }

  .article-navigation .right .dashboard-top-select {
    font-size: 1.2rem;
    padding: 0 1rem;
    border-radius: 0.5rem;
  }

  .points-container {
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 1.5rem;
  }

  .points-container .single-point-container {
    padding: 1rem;
  }

  .points-container .single-point-container:first-child,
  .points-container .single-point-container:last-child {
    border-radius: 1.5rem;
  }

  .points-container .single-point-container img {
    width: 40px;
  }

  .points-container .single-point-container .right span {
    font-size: 1.2rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 2.5rem;
    line-height: 1;
  }

  .article {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .bottom-left {
    padding-bottom: 5rem;
  }
}

@media (max-width: 2050px) and (max-height: 1080px) {
}

@media (max-width: 1700px) and (max-height: 960px) {
  .container {
    grid-template-columns: minmax(100px, 1500px) minmax(100px, 400px);
    row-gap: 2rem;
  }

  .article {
    border-radius: 2rem;
  }

  .orange-rect {
    width: 15px;
    height: 30px;
    border-radius: 0.3rem;
  }

  .article-navigation .left h1 {
    font-size: 1.2rem;
  }

  .article-navigation .right .dashboard-top-select {
    font-size: 1.1rem;
    padding: 0 1rem;
    border-radius: 0.5rem;
  }

  .top-performers-container .top * {
    font-size: 1rem;
  }

  .top-performers-container .top .find-me-box {
    padding: 0.3rem 1rem;
    border-radius: 0.5rem;
  }

  .top-performers-container .bottom .item img {
    width: 70px;
    height: 50px;
    border-radius: 35px;
    object-fit: cover;
  }

  .bottom-right {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .bottom-right .today-session-box {
    padding: 0.3rem 1.3rem;
    border-radius: 0.3rem;
    font-size: 1rem;
  }

  .bottom-right .today-session-list {
    grid-template-columns: 2fr 1fr;
    row-gap: 1.5rem;
    column-gap: 0.5rem;
  }

  .bottom-right .today-session-list div {
    font-size: 1rem;
  }

  .bottom-right .text .divider {
    margin-bottom: 0.5rem !important;
  }

  .bottom-right .text p {
    font-size: 0.9rem;
  }

  .bottom-right .today-session-btn {
    padding: 0.3rem 2rem;
    font-size: 1rem;
    border-radius: 0.4rem;
  }
}

@media (max-width: 1700px) and (max-height: 880px) {
  .container {
    grid-template-columns: minmax(100px, 1500px) minmax(100px, 350px);
    grid-template-rows: minmax(100px, 500px) 5rem minmax(100px, 500px);
  }

  .points-container {
    border-radius: 1rem;
  }

  .points-container .single-point-container:first-child,
  .points-container .single-point-container:last-child {
    border-radius: 1rem;
  }

  .points-container .single-point-container .right span {
    font-size: 1rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 2rem;
  }

  .top-performers-container .bottom .item span {
    font-size: 0.9rem;
  }

  .bottom-right {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .bottom-right .today-session-list {
    row-gap: 1rem;
  }

  .bottom-right .today-session-list div {
    font-size: 0.9rem;
  }

  .bottom-right .today-session-list .right {
    text-align: left;
  }
}

@media (max-width: 1700px) and (max-height: 770px) {
  .container {
    row-gap: 1.5rem;
  }

  .points-container {
    border-radius: 0.7rem;
  }

  .points-container .single-point-container {
    padding: 0.5rem;
  }

  .points-container .single-point-container:first-child,
  .points-container .single-point-container:last-child {
    border-radius: 0.7rem;
  }

  .points-container .single-point-container .right span {
    font-size: 0.9rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 1.5rem;
  }

  .points-container .single-point-container img {
    width: 30px;
  }

  .top-performers-container .bottom .item img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover;
  }

  .top-performers-container .bottom .item span {
    margin-top: 0.3rem;
    font-size: 0.8rem;
  }

  .bottom-right .today-session-box {
    font-size: 0.9rem;
  }

  .bottom-right .today-session-list div {
    font-size: 0.8rem;
  }

  .bottom-right .text p {
    font-size: 0.8rem;
  }

  .bottom-right .today-session-btn {
    padding: 0.2rem 1.5rem;
    font-size: 0.9rem;
    border-radius: 0.3rem;
  }
}

@media (max-width: 1400px) and (max-height: 690px) {
  .article-navigation .left {
    column-gap: 0.5rem;
  }

  .orange-rect {
    width: 10px;
    height: 20px;
    border-radius: 0.2rem;
  }

  .article-navigation .left h1 {
    font-size: 1rem;
  }

  .article-navigation .right .dashboard-top-select {
    font-size: 0.9rem;
    padding: 0 1rem;
    border-radius: 0.3rem;
  }

  .points-container .single-point-container {
    column-gap: 1rem;
  }

  .points-container .single-point-container .right span {
    font-size: 0.8rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 1.2rem;
  }

  .points-container .single-point-container img {
    width: 25px;
  }

  .top-performers-container .bottom .item img {
    width: 45px;
    height: 45px;
    border-radius: 23px;
    object-fit: cover;
  }

  .top-performers-container .bottom .item span {
    font-size: 0.8rem;
  }

  .top-performers-container .top {
    margin: 1rem 0;
  }

  .top-performers-container .top * {
    font-size: 0.9rem;
  }

  .top-performers-container .top .find-me-box {
    border-radius: 0.3rem;
    font-size: 0.8rem;
  }

  .bottom-right .text p {
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 1800px) and (max-height: 750px) {
  .container {
    grid-template-columns: minmax(100px, 1500px) minmax(100px, 450px);
    grid-template-rows: minmax(100px, 500px) 5rem minmax(100px, 500px);
    row-gap: 1rem;
    column-gap: 10rem;
  }

  .article {
    border-radius: 2rem;
  }

  .bottom-right {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .bottom-right .today-session-box {
    font-size: 1rem;
    padding: 0.3rem 1rem;
  }

  .bottom-right .today-session-list {
    row-gap: 1rem;
  }

  .bottom-right .today-session-list div {
    font-size: 0.9rem;
  }

  .bottom-right .text p {
    font-size: 0.8rem;
  }

  .bottom-right .text .divider {
    margin-bottom: 0.5rem !important;
  }

  .bottom-right .today-session-btn {
    font-size: 0.9rem;
    padding: 0.3rem 1.5rem;
  }

  .article-navigation .left {
    column-gap: 0.5rem;
  }

  .orange-rect {
    width: 13px;
    height: 25px;
    border-radius: 0.2rem;
  }

  .article-navigation .left h1 {
    font-size: 1rem;
  }

  .article-navigation .right .dashboard-top-select {
    font-size: 0.9rem;
    padding: 0 1rem;
    border-radius: 0.4rem;
  }

  .points-container {
    margin-top: 1rem;
    padding: 0.5rem;
    border-radius: 1rem;
  }

  .points-container .single-point-container:first-child,
  .points-container .single-point-container:last-child {
    border-radius: 1rem;
  }

  .points-container .single-point-container {
    padding: 0.5rem;
    gap: 1rem;
  }

  .points-container .single-point-container img {
    width: 30px;
  }

  .points-container .single-point-container .right span {
    font-size: 0.9rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 1.4rem;
  }

  .top-performers-container .top {
    margin: 1rem 0;
  }

  .top-performers-container .top * {
    font-size: 0.9rem;
  }

  .top-performers-container .top .find-me-box {
    padding: 0.3rem 1rem;
    border-radius: 0.4rem;
  }

  .top-performers-container .bottom .item img {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    object-fit: cover;
  }

  .top-performers-container .bottom .item span {
    font-size: 0.9rem;
  }

  /* ================== */
  /* ===== / PC / ===== */
  /* ================== */
}

@media (min-width: 1700px) and (max-height: 950px) {
  .bottom-right {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .bottom-right .today-session-list {
    row-gap: 1.5rem;
  }

  .today-session-btn {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-size: 0.9rem !important;
  }
}

@media (min-width: 1700px) and (max-height: 900px) {
  .points-container .single-point-container {
    padding: 0.5rem;
  }

  .top-performers-container .bottom .item img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    object-fit: cover;
  }

  .points-container .single-point-container .right h1 {
    font-size: 2rem;
  }

  .top-performers-container .top {
    margin: 1rem 0;
  }

  .top-performers-container .top * {
    font-size: 1.1rem;
  }

  .top-performers-container .top .find-me-box {
    padding: 0.3rem 1rem;
    border-radius: 0.4rem;
  }
}

@media (min-width: 1700px) and (max-height: 860px) {
  .bottom-right .today-session-list div {
    font-size: 1rem;
  }

  .bottom-right .today-session-box {
    padding: 0.5rem 1.5rem;
    font-size: 1rem;
  }
}

@media (min-width: 1700px) and (max-height: 765px) {
  .container {
    row-gap: 1rem;
  }

  .bottom-right {
    padding-left: 5rem;
    padding-right: 5rem;
  }

  .bottom-right .today-session-list {
    row-gap: 1rem;
  }
}

@media (max-width: 1200px) {
  .container {
    grid-template-columns: minmax(100px, 500px) minmax(100px, 500px);
    grid-template-rows: auto minmax(300px, 500px) auto;
    grid-template-areas:
      'performers performers'
      'progressGraph progressGraph'
      'calendar session';
    height: auto;
    width: calc(100vw - 100px);
    max-width: 100%;
    padding-bottom: 4rem;
  }

  .bottom-right .today-session-list {
    margin: 2rem 0;
  }

  .teacher-dashboard {
    grid-template-rows: minmax(100px, 350px) minmax(300px, 500px) auto;
  }
}

@media (max-width: 1200px) and (min-height: 1000px) {
  .article {
    border-radius: 2rem;
  }

  .bottom-right .today-session-btn {
    font-size: 0.9rem;
    padding: 0.3rem 2rem;
  }

  .article-navigation .left {
    column-gap: 0.5rem;
  }

  .orange-rect {
    width: 15px;
    height: 30px;
    border-radius: 0.3rem;
  }

  .article-navigation .left h1 {
    font-size: 1.2rem;
  }

  .article-navigation .right .dashboard-top-select {
    font-size: 1.2rem;
    padding: 0rem 1rem;
    border-radius: 0.5rem;
    height: 40px;
  }

  .points-container {
    margin-top: 2rem;
    padding: 0.5rem;
    border-radius: 1rem;
  }

  .points-container .single-point-container:first-child,
  .points-container .single-point-container:last-child {
    border-radius: 1rem;
  }

  .points-container .single-point-container {
    padding: 0.5rem;
    gap: 0.5rem;
  }

  .points-container .single-point-container img {
    width: 30px;
  }

  .points-container .single-point-container .right span {
    font-size: 0.9rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 1.3rem;
  }

  .top-performers-container .top {
    margin: 2rem 0;
  }

  .top-performers-container .top * {
    font-size: 0.9rem;
  }

  .top-performers-container .top .find-me-box {
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
  }

  .top-performers-container .bottom .item img {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    object-fit: cover;
  }
}

@media (max-width: 950px) and (min-height: 1000px) {
  .bottom-right,
  .top-right {
    padding: 3rem;
  }

  .bottom-right .today-session-box {
    border-radius: 0.3rem;
  }

  .bottom-right .today-session-list {
    column-gap: 0.5rem;
  }

  .bottom-right .today-session-list div {
    font-size: 0.9rem;
  }
}

@media (max-width: 860px) and (min-height: 1000px) {
  .container {
    column-gap: 1rem;
    grid-template-rows: auto minmax(300px, 500px) minmax(300px, 500px);
    margin: 0 0 0 60px;
    padding: 0;
    padding-left: 40px;
    padding-bottom: 4rem;
  }

  .teacher-dashboard {
    grid-template-rows: minmax(100px, 300px) minmax(300px, 500px) minmax(
        300px,
        500px
      );
  }

  .bottom-right {
    padding: 3rem 2rem;
  }

  .bottom-right .today-session-list .left {
    column-gap: 0.3rem;
  }

  .bottom-right .today-session-list .left *:first-child {
    font-size: 1.1rem;
  }

  .article-navigation .right .dashboard-top-select {
    height: 30px;
    padding: 0;
    border-radius: 0.3rem;
    font-size: 0.9rem;
  }
}

@media (max-width: 725px) and (min-height: 1000px) {
  .bottom-left {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .container {
    grid-template-rows: auto minmax(300px, 350px) minmax(300px, 400px);
  }

  .teacher-dashboard {
    grid-template-rows: minmax(100px, 300px) minmax(300px, 350px) minmax(
        300px,
        400px
      );
  }

  .article {
    border-radius: 1.5rem;
  }

  .top-right,
  .bottom-right {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .bottom-right .today-session-list {
    row-gap: 1rem;
    margin: 0;
  }
}

@media (max-width: 720px) {
  .container {
    grid-template-columns: minmax(100px, 500px);
    grid-template-rows: auto minmax(300px, 350px) auto auto;
    grid-template-areas:
      'performers'
      'progressGraph'
      'calendar'
      'session';
    margin: 0 auto 0 60px;
    padding-left: 40px;
  }

  .teacher-dashboard {
    grid-template-rows: minmax(100px, 300px) minmax(300px, 350px) auto auto;
  }

  .bottom-right .today-session-list {
    margin: 2rem 0;
    row-gap: 2rem;
  }

  .bottom-right {
    padding-left: 7rem;
    padding-right: 7rem;
  }
}

@media (max-width: 640px) {
  .points-container .single-point-container {
    flex-direction: column;
    gap: 0;
    width: 100%;
  }

  .points-container .single-point-container * {
    text-align: center;
    width: 100%;
  }
}
.cover-whitespace {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
@media (max-width: 599px) {
  .container {
    grid-template-rows: 1fr;
    grid-template-rows: auto minmax(300px, 350px) auto minmax(500px, 600px);
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 4rem;
    width: 100%;
  }

  .teacher-dashboard {
    grid-template-rows: minmax(100px, 300px) minmax(300px, 350px) auto minmax(
        500px,
        600px
      );
  }
}

@media (max-width: 599px) and (max-height: 1000px) {
  .container {
    grid-template-rows: 1fr;
    grid-template-rows: auto minmax(300px, 350px) auto minmax(500px, 600px);
    margin: 0;
    margin-top: 1rem;
    padding: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 4rem;
    width: 100%;
  }

  .teacher-dashboard {
    grid-template-rows: minmax(100px, 300px) minmax(300px, 350px) auto minmax(
        500px,
        600px
      );
  }

  .bottom-right .today-session-list {
    row-gap: 3rem;
  }

  .bottom-right {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .article-navigation .left {
    column-gap: 0.4rem;
  }

  .article-navigation .orange-rect {
    width: 15px;
    height: 30px;
    border-radius: 0.3rem;
  }

  .article-navigation .left h1 {
    font-size: 1.2rem;
  }

  .article-navigation .right .dashboard-top-select {
    height: 40px;
    padding: 0 0.5rem;
    font-size: 0.9rem;
  }

  .points-container .single-point-container .right span {
    display: inline-block;
    line-height: 1.2;
    font-size: 0.8rem;
  }

  .points-container .single-point-container .right h1 {
    font-size: 1.3rem;
  }

  .article:not(.bottom-left) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
  }

  .top-performers-container .top * {
    font-size: 0.9rem;
  }

  .top-performers-container .top .find-me-box {
    padding: 0.3rem 1rem;
    border-radius: 0.3rem;
  }

  .top-performers-container .bottom .item img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    object-fit: cover;
  }

  .top-performers-container .bottom .item span {
    font-size: 0.8rem;
  }

  .bottom-left {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 5rem;
    border-radius: 1rem;
  }

  .bottom-right {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .bottom-right .today-session-list div {
    font-size: 0.9rem;
  }
}

@media (max-width: 415px) and (max-height: 1000px) {
  .container {
    row-gap: 2rem;
  }

  .bottom-right .today-session-btn {
    font-size: 0.9rem;
    padding: 0.3rem 2rem;
    border-radius: 0.4rem;
  }
}

@media (max-width: 375px) and (max-height: 1000px) {
  .bottom-right {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

/* @media (max-width: 380px) and (max-height: 1000px) {
  .points-container .single-point-container .right span {
    display: inline-block;
    line-height: 1.2;
  }
} */

/* @media (max-width: 600px) and (max-height: 1000px) {
  
} */

/* ===================================== */
/* =========== MEDIA QUERIES =========== */
/* ===================================== */

/* ======================================================= */
/* ================= / WELCOME - START / ================= */
/* ======================================================= */

.welcome-page-container,
.signup-container {
  height: 100vh;
  position: relative;
}

.welcome-bag-container {
  background-color: #f8d2f9;
  width: 90vw;
  margin: 5rem auto;
  max-width: 1000px;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0.3rem 0.5rem 0.2rem rgba(0, 0, 0, 0.3);
  position: relative;
}

.welcome-text,
.signup-text {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.welcome-text h1,
.signup-text h1 {
  font-size: 3rem;
}

.welcome-text .welcome-login-btn,
.signup-text .signup-button {
  font-size: 1.3rem;
  padding: 0.5rem 2rem;
  margin-top: 3rem;
}

.welcome-page-container > img,
.signup-container > img {
  position: absolute;
  bottom: 10vh;
  width: 25vw;
  min-width: 100px;
}

.welcome-page-container > img:last-of-type,
.signup-container > img:last-of-type {
  right: 0;
  width: 20vw;
}

@media (max-height: 1100px) {
  .welcome-page-container > img,
  .signup-container > img {
    bottom: 5vh;
  }
}

@media (max-width: 1650px) {
  .welcome-bag-container {
    border-radius: 2rem;
  }

  .welcome-bag-container img {
    width: 250px;
  }

  .welcome-text h1,
  .signup-text h1 {
    font-size: 2rem;
  }

  .welcome-text .welcome-login-btn,
  .signup-text .signup-button {
    font-size: 1rem;
    padding: 0.5rem 2rem;
    margin-top: 1rem;
  }

  .welcome-page-container > img:first-of-type,
  .signup-container > img:first-of-type {
    width: 20vw;
    left: 5rem;
  }

  .welcome-page-container > img:last-of-type,
  .signup-container > img:last-of-type {
    width: 15vw;
    right: 5rem;
  }
}

@media (max-width: 1550px) and (max-height: 900px) {
  .welcome-bag-container {
    max-width: 700px;
  }

  .welcome-bag-container img {
    width: 250px;
  }

  .welcome-page-container > img,
  .signup-container > img {
    bottom: 2rem;
  }
}

@media (max-width: 1400px) {
  .welcome-bag-container {
    max-width: 600px;
  }

  .welcome-bag-container img {
    width: 200px;
  }
}

@media (min-width: 1800px) and (max-height: 960px) {
  .welcome-bag-container img {
    width: 200px;
  }

  .welcome-bag-container {
    max-width: 500px;
  }

  .welcome-text h1,
  .signup-text h1 {
    font-size: 1.7rem;
  }

  .welcome-text .welcome-login-btn,
  .signup-text .signup-button {
    font-size: 1rem;
    padding: 0.5rem 2rem;
    margin-top: 1rem;
  }

  .welcome-page-container > img:first-of-type,
  .signup-container > img:first-of-type {
    width: 15vw;
    left: 5rem;
  }

  .welcome-page-container > img:last-of-type,
  .signup-container > img:last-of-type {
    width: 10vw;
    right: 5rem;
  }
}

@media (max-width: 600px) {
  .welcome-text h1,
  .signup-text h1 {
    font-size: 1.3rem;
  }

  .welcome-text .welcome-login-btn,
  .signup-text .signup-button {
    font-size: 0.9rem;
  }
}

@media (max-width: 450px) {
  .welcome-page-container > img:first-of-type,
  .signup-container > img:first-of-type {
    left: 2rem;
  }

  .welcome-page-container > img:last-of-type,
  .signup-container > img:last-of-type {
    right: 2rem;
  }
}

@media (max-width: 1000px) {
  .welcome-page-container > img:first-of-type,
  .signup-container > img:first-of-type {
    left: 1rem;
    top: 30rem;
    min-width: 170px;
  }

  .welcome-page-container > img:last-of-type,
  .signup-container > img:last-of-type {
    right: 1rem;
    top: 30rem;
    min-width: 170px;
  }
}

@media (max-width: 599px) {
  .welcome-page-container > img:first-of-type,
  .signup-container > img:first-of-type,
  .welcome-page-container > img:last-of-type,
  .signup-container > img:last-of-type {
    min-width: 140px;
  }
}

@media (max-width: 450px) {
  .welcome-page-container > img:first-of-type,
  .signup-container > img:first-of-type,
  .welcome-page-container > img:last-of-type,
  .signup-container > img:last-of-type {
    top: 35rem;
  }
}

/* ===================================================== */
/* ================= / WELCOME - END / ================= */
/* ===================================================== */

/* ======================================================= */
/* ================== / HEADER PROFILE / ================= */
/* ======================================================= */

.header-profile-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15%;
}

.header-profile-container .avatar-icon {
  width: 50px;
  height: 50px;
  outline: 2px solid #efefef;
  padding: 0rem;
  border-radius: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
}

.header-profile-container img {
  width: 100%;
  height: 100%;
}

@media (max-width: 1600px) {
  .header-profile-container *:first-of-type {
    font-size: 1.2rem;
  }

  .header-profile-container .avatar-icon {
    width: 30px;
    height: 30px;
    padding: 0rem;
    border-radius: 0.3rem;
  }
}

@media (max-width: 599px) {
  .header-profile-container {
    right: 5%;
  }
}
@media (max-width: 650px) {
  .selected-club {
    width: '50%';
    font-size: 0.6rem;
  }
}

@media (max-width: 650px) {
  #standard-read-only-input {
    font-size: 0.4rem;
    font-weight: 500;
    word-wrap: break-word;
    overflow: hidden;
    overflow-wrap: break-word;
    padding-left: 0.1rem;
    padding-right: 0.7rem;
    text-align: justify;
    height: 0.7rem;
  }
}
/* ======================================================= */
/* ================== / HEADER PROFILE / ================= */
/* ======================================================= */

.hoverable {
  cursor: pointer;
}
.hoverable:hover {
  background-color: #efefef;
}
