.profile-page-content-container {
  height: calc(100vh - 64px);
  margin-left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-page-container {
  background-color: #fff;
  width: clamp(100px, 90%, 2000px);
  height: clamp(300px, 90%, 1000px);
  border-radius: 3rem;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3), 0 0 1rem rgba(0, 0, 0, 0.3);
  padding: 3rem;
}
.profile-page-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  column-gap: 2rem;
  margin-top: 2rem;
}

/* ======================================== */
/* ======== / NAVIGATION - START / ======== */
/* ======================================== */

.profile-page-navigation {
  width: 300px;
}
.profile-page-navigation * {
  color: #1a1d1f;
  font-weight: 500;
}

/* ====================================== */
/* ======== / NAVIGATION - END / ======== */
/* ====================================== */

/* ====================================== */
/* ======== / SETTINGS - START / ======== */
/* ====================================== */

.profile-settings-title {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}
.profile-page-orange-rect {
  width: 20px;
  height: 40px;
  border-radius: 0.3rem;
  background-color: #ff592c;
}

.profile-settings-content > img {
  width: 160px;
  height: 160px;
  margin: 2rem 0;
  border-radius: 50%;
}

.profile-settings-content
  *:not(.profile-settings-content .form-group input:disabled) {
  font-weight: 500;
  color: #33383f;
}

.profile-settings-content .form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 0.5rem;
  margin-bottom: 2rem;
}

.profile-settings-content .form-group input {
  width: 1000px;
  background-color: #f4f4f4;
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 1rem;
  font-size: 1rem;
}

.profile-settings-btn {
  background-color: #f4f4f4 !important;
  box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.2);
  width: 50%;
  padding: 1rem !important;
  margin-bottom: 1rem !important;
  border-radius: 1rem !important;
}

.profile-settings-btn div {
  width: 100%;
  text-align: center;
  font-weight: 600 !important;
}

.profile-settings-content > button {
  background-color: #ff592c;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  color: #fff !important;
  font-size: 1rem;
}

/* ==================================== */
/* ======== / SETTINGS - END / ======== */
/* ==================================== */

/* =================================== */
/* ======== / GOALS - START / ======== */
/* =================================== */

.goals-profile-content {
  width: 1000px;
}
.goals-profile-content * {
  font-weight: 600;
  color: #33383f;
}

.goals-profile-content .profile-settings-title {
  justify-content: space-between;
}
.goals-profile-content .profile-settings-title .left {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.profile-goals-select {
  height: 40px !important;
}

.progress-bar-profile {
  outline: 1px solid #cabdff;
  border-radius: 0.5rem;
}
.progress-bar-group div div span {
  margin-left: 1rem;
}
.profile-progress-bar-container {
  position: relative;
}
.profile-progress-bar-container h3 {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1rem;
  font-weight: 700;
  font-size: 1rem;
  color: #646970;
}

.goals-profile-content-inner {
  margin-top: 4rem;
}
.goals-profile-content-inner > h2 {
  font-weight: 600;
}
.goals-profile-content-inner .progress-bar-group {
  margin-top: 1rem;
}
.goals-profile-content-inner .progress-bar-group:last-of-type {
  margin-top: 2rem;
}
.goals-profile-content-inner .progress-bar-group label {
  color: #6f767e;
  margin-bottom: 0.5rem;
  display: inline-block;
}

.goals-profile-content-inner > h2:last-of-type {
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.rewards-container-goals {
  display: flex;
  align-items: flex-start;
  margin-top: 0.2rem;
}
.rewards-container-goals-content {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.3);
  padding: 0.5rem 0.8rem;
  border-radius: 1rem;
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
}
.rewards-container-goals-content img {
  width: 40px;
}
.rewards-container-goals-content span {
  color: #6f767e;
}

/* ================================= */
/* ======== / GOALS - END / ======== */
/* ================================= */

@media (max-width: 1500px) {
  .profile-settings-content .form-group input,
  .goals-profile-content {
    width: 700px;
  }
}
@media (max-width: 1200px) {
  .profile-settings-content .form-group input,
  .goals-profile-content {
    width: 600px;
  }
}
@media (max-width: 1050px) {
  .profile-settings-content .form-group input,
  .goals-profile-content {
    width: 400px;
  }
}
@media (max-width: 800px) {
  .profile-settings-content .form-group input {
    width: 300px;
  }
  .profile-settings-btn {
    width: 100%;
  }
}
@media (max-width: 760px) {
  .goals-profile-content {
    width: 350px;
  }
}
@media (max-width: 700px) {
  .goals-profile-content {
    width: 300px;
  }
}

@media (max-width: 680px) {
  .profile-page-content-container {
    align-items: flex-start;
    padding: 2rem 0;
    height: auto;
  }
  .profile-page-component,
  .goals-profile-content {
    width: 100%;
  }
  .profile-page-container {
    height: auto;
  }
  .profile-page-content {
    flex-direction: column;
    row-gap: 2rem;
  }
  .profile-page-navigation {
    width: 100%;
  }
  .profile-settings-content .form-group input {
    width: 100%;
  }
}

@media (max-width: 640px) {
  .profile-page-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 599px) {
  .profile-page-content-container {
    margin-left: 0;
  }
  .profile-page-container {
    border-radius: 1.5rem;
  }
  .profile-settings-content > img {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 450px) {
  .goals-profile-content .profile-settings-title {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 1rem;
  }
}

@media (max-height: 1115px) {
  .profile-settings-content > img {
    width: 100px;
    height: 100px;
  }
}
@media (max-height: 1050px) {
  .profile-page-content {
    margin-top: 0.3rem;
  }
}
@media (max-height: 1020px) {
  .profile-page-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .profile-settings-content > img {
    margin: 1rem 0;
  }
}
@media (max-height: 960px) {
  .profile-page-orange-rect {
    width: 15px;
    height: 35px;
  }
  .profile-settings-content .form-group {
    row-gap: 0.3rem;
    margin-bottom: 1rem;
  }
}
@media (max-height: 890px) {
  .profile-settings-content .form-group input {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }
  .profile-settings-btn {
    padding: 0.5rem 1rem !important;
  }
}
@media (max-height: 815px) {
  .profile-settings-content > button {
    padding: 0.5rem 1rem;
  }
  .profile-settings-content > img {
    margin: 0.5rem 0;
  }
}
@media (max-height: 780px) {
  .profile-page-component {
    margin-top: -3rem;
  }
  .profile-settings-content > img {
    width: 80px;
    height: 80px;
  }
}
@media (max-height: 730px) {
  .goals-profile-content-inner > h2:last-of-type {
    margin-top: 2rem;
  }
  .goals-profile-content-inner {
    margin-top: 2rem;
  }
}
@media (max-height: 700px) {
  .profile-settings-content * {
    font-size: 0.9rem;
  }
  .profile-settings-btn {
    margin-bottom: 0.5rem !important;
  }
  .profile-settings-content > button {
    padding: 0.3rem 1rem;
  }
}
@media (max-height: 660px) {
  .profile-settings-content > img {
    width: 50px;
    height: 50px;
  }
  .rewards-container-goals-content h1 {
    font-size: 1.5rem;
    line-height: 1;
  }
  .rewards-container-goals-content img {
    width: 30px;
    height: 30px;
  }
  .progress-bar-profile,
  .progress-bar-profile div,
  .progress-bar-profile div div {
    height: 40px !important;
  }
}
@media (max-height: 620px) {
  .profile-page-content-container {
    align-items: flex-start;
    height: clamp(200px, 95%, 1000px);
  }
}
