.trainer_cont {
  min-height: 80vh;
  width: 85vw;
  background: #ffffff;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2.5em;
  border-radius: 8px;
}
.trainer_head {
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 700;
  font-size: 1.95em;
  line-height: 56px;
  letter-spacing: -0.01em;
  color: #22252d;
}
.trainer_subhead {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 0.9em;
  line-height: 24px;
  color: #31343f;
  width: 75%;
  padding: 1em 0%;
  border-bottom: 2px solid #f3f3f0;
}
.trainer_lighthead {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 0.9em;
  line-height: 24px;
  color: #7f8596;
}

.stats_cont {
  display: flex;
  gap: 4%;
}
.levels_box {
  display: flex;
  flex-direction: column;
  width: 48%;
  gap: 10px;
}
.levels_cont {
  display: flex;
  justify-content: space-around;
}
.levels {
  display: flex;
  width: 75%;
  gap: 0.8em;
}
.levels img {
  object-fit: contain;
  width: 1.5em;
}
.levels div {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 24px;
  color: #7f8596;
}
.level_count {
  display: flex;
  width: 25%;
  justify-content: flex-end;
  align-content: flex-end;
}
.level_count div {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.5em;
  color: #ffffff;
  padding: 0.03em 0.5em;
  border-radius: 0.4em;
  background-color: #30b38c;
}
.workout_cont {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px 20px;
  gap: 4px;
  width: 15%;
  height: fit-content;
  background: #ffffff;
  border: 2px solid transparent;
  border-radius: 12px;
}
.workouts_row {
  display: flex;
  gap: 0.4em;
}
.workout_cont .name {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 1.75em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #22252d;
}

.workout_cont .img_cont {
  width: 4em;
  height: 4em;
  border-radius: 2em;
  background-color: #c0d7b6;
}
.workout_cont .img_cont img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.reset-box {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border: 2px solid rgba(127, 133, 150, 0.3);
  border-radius: 8px;
  align-self: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #22252d;
}
