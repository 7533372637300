.point-component {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  margin: 0 auto;
  width: clamp(100px, 95%, 800px);
  background-color: rgba(0, 0, 0, 0.05);
  padding: 0.5rem;
  border-radius: 1rem;
  position: relative;
}

.single-point-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  flex-basis: 100%;
  padding: 1rem;
  border-radius: 1rem;
  position: relative;
}

.tooltiptext {
  opacity: 0;
  width: 120px;
  background-color: rgb(53, 3, 3);
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  font-size: 0.8rem;

  position: absolute;
  z-index: 1;
  transform: translateY(calc(100% + 20px));
  pointer-events: none;
  transition: 200ms ease-in-out opacity;
}

.single-point-container:hover .tooltiptext {
  opacity: 1;
}

.single-point-container:first-child,
.single-point-container:last-child {
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.single-point-container img {
  width: 50px;
}

@media (max-width: 1575px) {
  .single-point-container .right br {
    display: block;
  }
}

@media (max-width: 1500px) {
  .single-point-container img {
    width: 35px;
  }
}

@media (max-width: 1055px) {
  .single-point-container {
    padding: 0.5rem;
  }

  .single-point-container img {
    width: 30px;
  }

  .single-point-container .right p {
    font-size: 0.9rem;
  }
}

@media (max-width: 850px) {
  .single-point-container .right p {
    font-size: 0.8rem;
  }
}

@media (max-width: 750px) {
  .point-component {
    padding: 0.3rem;
  }

  .single-point-container img {
    width: 20px;
  }

  .single-point-container .right p {
    font-size: 0.7rem;
  }
}

@media (max-height: 1150px) {
  .single-point-container {
    padding: 0.5rem;
  }

  .single-point-container img {
    width: 40px;
  }
}

@media (max-height: 1000px) {
  .point-component {
    padding: 0.3rem;
  }

  .single-point-container img {
    width: 20px;
  }

  .single-point-container .right p {
    font-size: 0.7rem;
  }
}

@media (max-height: 900px) {
  .single-point-container .right h1 {
    font-size: 1rem;
  }

  .point-component {
    width: clamp(100px, 95%, 600px);
  }
}

@media (max-height: 810px) {
  .point-component {
    border-radius: 0.7rem;
  }

  .single-point-container {
    border-radius: 0.7rem;
  }
}

@media (max-height: 600px) {
  .item img {
    margin-top: 0.7rem;
  }
}

@media (max-width: 550px) {
  .single-point-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .single-point-container img {
    width: 30px;
  }
}

@media (max-width: 445px) {
  .single-point-container img {
    width: 25px;
  }
}

@media (max-width: 350px) {
  .single-point-container img {
    width: 20px;
  }
}
