.ranking-container {
    display: grid;
    place-content: center;
}

.ranking-container .ranking-grid * {
    color: #282828;
}

.ranking-container .ranking-grid {
    margin: 30px auto 0 60px;
    padding: 0 100px 4rem;
    display: grid;
    grid-template-columns: minmax(100px, 2000px);
    row-gap: 5rem;
}

.ranking-container .ranking-article {
    background-color: #fcfcfc;
    border-radius: 3rem;
    padding: 3rem;
    box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.2);
}

.ranking-container .top-three-rankers {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 60%;
    margin: 5rem auto 0;
}

.ranking-container .ranking-article-navigation {
    display: flex;
    align-items: center;
    column-gap: 1.5rem;
}

.ranking-container .ranking-orange-rect {
    width: 25px;
    height: 50px;
    background-color: #ff592c;
    border-radius: 0.5rem;
}

.ranking-container .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.ranking-container .item:nth-child(2) {
    transform: translateY(-30%);
}

.ranking-container .item img {
    width: 120px;
    height: 120px;
    border-radius: 60px;
}

.ranking-container .item span {
    margin-top: 1rem;
    font-size: 1.2rem;
}

.all-students-container .all-students-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7rem;
    column-gap: 5rem;
}

.all-students-container .all-students-navigation .title {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

.all-students-container .all-students-navigation .title h1 {
    font-weight: 500;
}

.all-students-container .all-students-navigation .violet-rect {
    width: 25px;
    height: 50px;
    background-color: #cabdff;
    border-radius: 0.5rem;
}

.all-students-container .all-students-navigation .search {
    background-color: #f4f4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0.2rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
}

.all-students-container .all-students-navigation .searchcont {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f4f4;
    box-shadow: 0.1rem 0.2rem 0.5rem rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 1rem;
}

.all-students-container .all-students-navigation .searchcont input {
    background-color: transparent;
    border: none;
    outline: none;
    height: 100%;
    padding-left: 1rem;
    font-size: 1.2rem;
}

.all-students-container .all-students-navigation .searchcont img {
    width: 20px;
}

.all-students-container .gridder {
    margin-bottom: 2rem;
}

.all-students-container .gridder span {
    font-size: 1.2rem;
    color: #727981;
}

.all-students-container .gridder,
.all-students-container .studet-row {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: center;
}

.all-students-container .studet-row {
    padding: 3rem 0;
    border-bottom: 2px solid #dadada;
}

.all-students-container .studet-row .student-row-item-inner {
    display: flex;
    align-items: center;
    column-gap: 1rem;
}

/* .avtar {
  height: 6rem;
  width: 6rem;
  border-radius: 3rem;
} */
.avtar2 {
    height: 7rem;
    width: 7rem;
    border-radius: 3.5rem;
}

.all-students-container .studet-row .student-row-item img {
    width: 100px;
    height: 100px;
    border-radius: 50px;
}

.all-students-container .studet-row .val-item h3 {
    background-color: #f0f0f0;
    display: inline-block;
    box-shadow: 0.1rem 0.2rem 0.3rem rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    border-radius: 0.3rem;
}

.all-students-container .studet-row h3 {
    font-size: 1.4rem;
}

@media (max-width: 1750px) {
    .ranking-container .ranking-article-navigation {
        column-gap: 1rem;
    }

    .ranking-container .ranking-article-navigation h1,
    .all-students-container .all-students-navigation .title h1 {
        font-size: 1.5rem;
    }

    .ranking-container .ranking-article-navigation .ranking-orange-rect,
    .all-students-container .all-students-navigation .violet-rect {
        width: 20px;
        height: 35px;
        border-radius: 0.4rem;
    }

    .ranking-container .top-three-rankers {
        width: 80%;
    }

    .ranking-container .item img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
    }

    .all-students-container .all-students-navigation .searchcont {
        padding: 1rem;
        border-radius: 0.7rem;
    }

    .all-students-container .all-students-navigation .searchcont input {
        font-size: 1rem;
    }

    .all-students-container .all-students-navigation .searchcont img {
        width: 20px;
    }

    .all-students-container .gridder span {
        font-size: 1rem;
    }

    .all-students-container .studet-row {
        padding: 2.5rem 0;
    }

    .all-students-container .studet-row .student-row-item img {
        width: 70px;
        height: 70px;
        border-radius: 35px;
    }

    .all-students-container .studet-row .val-item h3 {
        padding: 0.3rem 0.5rem;
    }

    .all-students-container .studet-row h3 {
        font-size: 1rem;
    }
}

@media (max-width: 1000px) {
    .ranking-container .ranking-grid {
        padding: 0 2rem 4rem;
    }

    .ranking-container .top-three-rankers {
        width: 100%;
    }

    .all-students-container .gridder,
    .all-students-container .studet-row {
        grid-template-columns: 1.3fr 1fr 1fr 0.5fr 1fr 1fr;
    }
}

@media (max-width: 860px) {
    .ranking-container .ranking-grid {
        row-gap: 3rem;
    }

    .ranking-container .top-three-rankers {
        justify-content: space-around;
    }

    .ranking-container .item img {
        width: 80px;
        height: 80px;
        border-radius: 40px;
    }

    .all-students-container .all-students-navigation {
        justify-content: space-between;
        column-gap: 1rem;
    }

    .all-students-container .all-students-navigation .searchcont input {
        font-size: 0.9rem;
    }

    .all-students-container .all-students-navigation .searchcont img {
        width: 20px;
    }
}

@media (max-width: 765px) {
    .all-students-container .all-students-navigation {
        flex-direction: column;
        column-gap: 0;
        row-gap: 2rem;
    }

    .all-students-container .studet-row .student-row-item-inner {
        flex-direction: column;
        align-items: flex-start;
        display: inline-block;
    }

    .all-students-container .studet-row .student-row-item h3 {
        text-align: center;
    }

    .all-students-container .gridder,
    .all-students-container .studet-row {
        grid-template-columns: repeat(6, 1fr);
        justify-content: center;
    }

    .all-students-container .all-students-navigation {
        margin-bottom: 4rem;
    }

    .all-students-container .studet-row .val-item,
    .all-students-container .studet-row .student-row-item,
    .all-students-container .gridder span {
        margin: 0 auto;
    }
}

@media (max-width: 650px) {
    .ranking-container .ranking-article {
        padding: 2rem;
        border-radius: 2rem;
    }

    .ranking-container .top-three-rankers {
        justify-content: space-between;
    }

    .ranking-container .item img {
        width: 60px;
        height: 60px;
        border-radius: 30px;
    }

    .ranking-container .item span {
        font-size: 0.9rem;
        margin-top: 0.3rem;
    }

    .ranking-container .ranking-article-navigation {
        column-gap: 0.5rem;
    }

    .all-students-container .all-students-navigation .title {
        column-gap: 0.5rem;
    }

    .ranking-container .ranking-article-navigation h1,
    .all-students-container .all-students-navigation .title h1 {
        font-size: 1.2rem;
    }

    .ranking-container .ranking-article-navigation .ranking-orange-rect,
    .all-students-container .all-students-navigation .violet-rect {
        width: 15px;
        height: 30px;
        border-radius: 0.3rem;
    }

    .all-students-container .all-students-navigation .searchcont {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-radius: 0.5rem;
    }

    .all-students-container .all-students-navigation .searchcont input {
        font-size: 0.9rem;
    }

    .all-students-container .all-students-navigation .searchcont img {
        width: 15px;
    }

    .all-students-container .gridder span {
        font-size: 0.9rem;
    }

    .all-students-container .studet-row .student-row-item img {
        width: 50px;
        height: 50px;
        border-radius: 25px;
    }
}

@media (max-width: 599px) {
    .ranking-container,
    *:not(.all-students-container .all-students-navigation .searchcont input) {
        text-align: center;
    }

    .ranking-container .ranking-grid {
        margin: 30px auto 0 auto;
    }

    .all-students-container .studet-row h3 {
        font-size: 0.8rem;
    }
}

@media (max-width: 480px) {
    .ranking-container .ranking-grid {
        padding: 0 1rem 4rem;
    }

    .ranking-container .ranking-article:not(:first-child) {
        padding-left: 0;
        padding-right: 0;
    }

    .all-students-container .all-students-navigation .searchcont input {
        width: 100%;
    }

    .all-students-container .all-students-navigation {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

@media (max-width: 400px) {
    .all-students-container .studet-row .student-row-item img {
        width: 35px;
        height: 35px;
        border-radius: 17.5px;
    }
}
