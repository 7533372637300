.sweat {
  max-height: 50%;
  max-width: 100%;
  border-radius: 12px;
  background-color: #fcfcfc;
  padding: 0;
}
.timer {
  background: linear-gradient(
    180deg,
    #444444 0%,
    rgba(102, 102, 102, 0.88) 100%
  );
  border-radius: 8px;
  font-family: DigitalNumbers;
  font-size: 0.85rem;
  padding: 5%;
  padding-left: 1rem;
  padding-right: 1rem;
  color: white;
  text-align: center;
  margin-bottom: 5px;
}

.timer2 {
  width: 100%;
  z-index: 1;
  align-content: center;
  text-align: center;
  margin-top: 5%;
  font-size: 40px;
  font-family: Impact, Haettenschweiler, Franklin Gothic Bold, Charcoal,
    Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif;
  font-weight: 900;
  opacity: 1;
  color: white;
}
.timer_container {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  align-content: center;
  text-align: center;
  margin-top: 0px;
  font-family: Impact, Haettenschweiler, Franklin Gothic Bold, Charcoal,
    Helvetica Inserat, Bitstream Vera Sans Bold, Arial Black, sans serif;
  font-weight: 700;
  opacity: 0.7;
  color: black;
  background-color: black;
}
.timer_container:before {
  content: '';
  position: absolute;
  background: inherit;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  filter: blur(10px);
  margin: 0px;
}
