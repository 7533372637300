.first_row {
    width: 100%;
    display: flex;
    gap: 8%;
    height: fit-content;
}

.many_items_row {
    width: 100%;
    display: flex;
    gap: 8%;
    height: fit-content;
    padding: 10px;
}

.img_container {
    width: 25%;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    cursor: pointer;
}

.img_container div {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    letter-spacing: -0.01em;
}

.img_container img {
    object-fit: contain;
    width: 55%;
}

.last-test {
    background-color: #fff;
    border-radius: 0.5rem;
    padding: 2rem 0.75rem;
    height: fit-content;
}

@media (max-height: 1260px) {
    .today-session-container .list-container {
        row-gap: 1.75rem !important;
    }
}

@media (max-width: 900px) {
    .img_container {
        width: 50%;
        height: 100%;
        gap: 0.5em;
    }

    .img_container div {
        font-style: normal;
        font-weight: 500;
        font-size: 0.85em;
    }

    .img_container img {
        width: 40%;
    }
}

@media (max-width: 600px) {
    .container-assessment {
        display: flex;
        flex-direction: column;
        gap: 2em;
    }
}

.assessment-path {
    padding: 10% 4px;
    height: fit-content;
    width: auto;
    margin-top: 1em;
    background-color: #fff;
    border-radius: 3rem;
    box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.3);
}

.assessment-cont {
    grid-area: performers;
    display: flex;
    flex-direction: column;
}
