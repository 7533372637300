@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

.top-right * {
  font-family: 'Poppins', sans-serif !important;
  text-align: center;
}

.top-right .react-calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.top-right .react-calendar__viewContainer {
  height: 100%;
  margin-top: 2rem;
}

.top-right .react-calendar__viewContainer .react-calendar__month-view {
  height: 100%;
}

.top-right .react-calendar__month-view > div {
  height: 100%;
}

.top-right .react-calendar__month-view > div > div {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  height: 100%;
}

.top-right .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.top-right .react-calendar__navigation__prev2-button,
.top-right .react-calendar__navigation__next2-button {
  display: none;
}

.top-right .react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 3rem;
}

.top-right .react-calendar__navigation button {
  text-transform: uppercase;
  font-size: 1.3rem;
  font-weight: 500;
  background-color: transparent;
  border: none;
}

.top-right .react-calendar__navigation button:nth-of-type(2),
.top-right .react-calendar__navigation button:nth-of-type(4) {
  outline: 2px solid #efefef;
  background-color: transparent;
  border: none;
  width: 30px;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  color: #7f8596;
}

.top-right
  .react-calendar__month-view__weekdays
  .react-calendar__month-view__weekdays__weekday
  abbr {
  font-weight: 600;
  color: #7f8596;
  text-transform: uppercase;
}

.top-right .react-calendar__tile {
  background-color: transparent;
  border: none;
  padding: 1rem 0;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
}

.top-right .react-top-right button:enabled:hover {
  cursor: pointer;
}

.react-calendar__month-view__days__day--neighboringMonth abbr {
  color: rgba(127, 133, 150, 0.3);
}

.top-right .react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.top-right .react-calendar__tile:enabled:hover,
.top-right .react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.top-right .react-calendar__tile--now {
  outline: 2px solid #e6e6e6;
  border-radius: 0.3rem;
}

.top-right .react-calendar__tile--now abbr {
  color: #e45f35 !important;
}

.top-right .react-calendar__tile--hasActive {
  background: rgba(118, 186, 255, 0.8);
}

.top-right .react-calendar__tile--hasActive:enabled:hover,
.top-right .react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.top-right .react-calendar__tile--active {
  color: #e45f35;
}

.top-right .react-calendar__tile--active:enabled:hover,
.top-right .react-calendar__tile--active:enabled:focus {
  background: rgba(118, 186, 255, 0.8);
}

.top-right .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

@media (max-width: 2560px) and (max-height: 1260px) {
  .top-right .react-calendar__tile {
    padding: 0.5rem 0;
  }
}

@media (max-width: 2050px) and (max-height: 1000px) {
  .top-right .react-calendar__tile {
    padding: 0.3rem 0;
  }
}

@media (max-width: 1700px) and (max-height: 965px) {
  .top-right {
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    row-gap: 0.5rem;
  }

  .top-right .react-calendar__navigation button {
    font-size: 1.1rem;
  }

  .top-right .react-calendar__navigation button:first-of-type,
  .top-right .react-calendar__navigation button:last-of-type {
    width: 30px;
    border-radius: 0.3rem;
  }

  .top-right
    .react-calendar__month-view__weekdays
    .react-calendar__month-view__weekdays__weekday
    abbr {
    font-size: 0.9rem;
  }

  .top-right .react-calendar__tile {
    font-size: 0.9rem;
  }
}

@media (max-width: 1700px) and (max-height: 930px) {
  .top-right .react-calendar__viewContainer {
    margin-top: 1rem;
  }
}

@media (max-width: 1700px) and (max-height: 880px) {
  .top-right .react-calendar__tile {
    padding: 0.2rem 0;
  }

  .top-right .react-calendar__month-view > div > div {
    row-gap: 1rem;
  }
}

@media (max-width: 1700px) and (max-height: 770px) {
  .top-right .react-calendar__navigation span {
    font-size: 1rem;
  }

  .top-right .react-calendar__navigation button:nth-of-type(2),
  .top-right .react-calendar__navigation button:nth-of-type(4) {
    width: 25px;
    height: 25px;
    border-radius: 0.3rem;
  }

  .top-right .react-calendar__tile {
    font-size: 0.8rem;
  }
}

@media (min-width: 1800px) and (max-height: 750px) {
  .top-right .react-calendar__month-view > div > div {
    row-gap: 0.3rem;
  }

  .top-right .react-calendar__viewContainer {
    margin-top: 0.3rem;
  }

  .top-right .react-calendar__navigation span {
    font-size: 1rem;
  }

  .top-right .react-calendar__navigation button:nth-of-type(2),
  .top-right .react-calendar__navigation button:nth-of-type(4) {
    width: 20px;
    height: 20px;
    border-radius: 0.2rem;
  }

  .top-right .react-calendar__tile {
    padding: 0.3rem 0;
    font-size: 0.7rem;
  }
}

@media (min-width: 1700px) and (max-height: 950px) {
  .top-right .react-calendar__month-view > div > div {
    row-gap: 1rem;
  }

  .top-right .react-calendar__viewContainer {
    margin-top: 1rem;
  }
}

@media (min-width: 1700px) and (max-height: 860px) {
  .top-right .react-calendar__month-view > div > div {
    row-gap: 0.5rem;
  }

  .top-right .react-calendar__viewContainer {
    margin-top: 0.5rem;
  }
}

@media (min-width: 1700px) and (max-height: 815px) {
  .top-right .react-calendar__tile {
    font-size: 0.8rem;
  }
  .top-right
    .react-calendar__month-view__weekdays
    .react-calendar__month-view__weekdays__weekday
    abbr {
    font-size: 0.9rem;
  }

  .top-right .react-calendar__navigation button {
    font-size: 1.1rem;
  }

  .top-right .react-calendar__navigation button:nth-of-type(2),
  .top-right .react-calendar__navigation button:nth-of-type(4) {
    width: 25px;
  }
}

@media (max-width: 1200px) {
  .top-right .react-calendar__viewContainer {
    margin-top: 3rem;
  }

  .top-right .react-calendar__tile {
    padding: 1.5rem 0;
  }

  .top-right .react-calendar__month-view > div > div {
    row-gap: 1rem;
  }
}

@media (max-width: 1070px) {
  .top-right {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@media (max-width: 860px) {
  .top-right {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .top-right .react-calendar__navigation {
    justify-content: space-between;
    column-gap: 0;
    width: 100%;
  }
}

@media (max-width: 860px) and (min-height: 1000px) {
  .top-right .react-calendar__viewContainer {
    margin-top: 2rem;
  }

  .top-right .react-calendar__tile {
    padding: 1.1rem 0;
  }
}

@media (max-width: 725px) and (min-height: 1000px) {
  .top-right .react-calendar__viewContainer {
    margin-top: 1rem;
  }

  .top-right .react-calendar__month-view > div > div {
    row-gap: 0rem;
  }

  .top-right .react-calendar__tile {
    padding: 1rem 0;
  }
}

@media (max-width: 720px) {
  .top-right .react-calendar__viewContainer {
    margin-top: 2rem;
  }

  .top-right .react-calendar__month-view > div > div {
    row-gap: 1rem;
  }
}

@media (max-width: 599px) {
  .top-right .react-calendar__tile {
    font-size: 0.8rem;
  }
}
