@import url(https://fonts.googleapis.com/css?family=Poppins:100,
  100italic, 200,
  200italic,
  300,
  300italic,
  regular,
  italic,
  500,
  500italic,
  600,
  600italic,
  700,
  700italic,
  800,
  800italic,
  900,
  900italic);
:root {
  --font-family: 'Poppins', sans-serif;
  --color-bg: #ffffff;
}
@font-face {
  font-family: 'DigitalNumbers';
  src: url('./assets/DigitalNumbers-Regular.ttf') format('truetype');
}
